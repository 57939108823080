import React, { Component } from 'react';
import Swiper from 'react-id-swiper/lib/index';
import { Autoplay, EffectFade } from 'swiper/dist/js/swiper.esm';
import styled from 'styled-components';

import 'swiper/dist/css/swiper.css';
import PreviewCompatibleImage from './Atoms/PreviewCompatibleImage';
import Box from './Atoms/Box';
import theme from '../styles/theme';

const SwiperStyler = styled.div`
  background: ${props => props.theme.color.gold};
  .swiper-container-horizontal > .swiper-pagination {
    text-align: left;
    bottom: 10%;
    padding-left: 8px;
    padding-right: 8px;
    @media screen and (min-width: 768px) {
      padding-left: 56px;
      padding-right: 56px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid ${props => props.theme.color.white};
    border-radius: 100%;
    opacity: 1;
    background: transparent;
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.theme.color.white};
  }
`;

class ImageSwiper extends Component {
  render() {
    const { list } = this.props;

    const params = {
      modules: [Autoplay, EffectFade],
      effect: 'fade',
      autoplay: {
        delay: 5000,
      },
    };

    return (
      <SwiperStyler>
        <Swiper {...params}>
          {list
            && list.map(({
              img, mobileImg, title, desc, link,
            }, index) => (
              <div key={index}>
                <Box display={['none', 'none', 'block']} height="100vh" overflowHidden backgroundColor={theme.color.darkBlue}>
                  <PreviewCompatibleImage imageInfo={img} fadeIn={false} />
                </Box>
                <Box display={['block', 'block', 'none']} height="100vh">
                  {mobileImg && <PreviewCompatibleImage imageInfo={mobileImg} fadeIn={false} />}
                </Box>
              </div>
            ))}
        </Swiper>
      </SwiperStyler>
    );
  }
}

export default ImageSwiper;
