import React, { Component } from 'react';
import { graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import RestaurantsHeader from '../components/Restaurants/RestaurantsHeader';
import RestaurantsDetail from '../components/Restaurants/RestaurantsDetail';
import RestaurantsTile from '../components/Restaurants/RestaurantsTile';

// ********************************* UI Template (Shared with CMS) *********************************
export class RestaurantPageTemplate extends Component {
  render() {
    const {
      title,
      description,
      coverIcon,
      name,
      desc,
      address,
      tel,
      coverImages,
      gridImages,
      cms = false,
    } = this.props;
    return (
      <div>
        <PageHelmet title={title} description={description} />
        <RestaurantsHeader images={coverImages} coverIcon={coverIcon} name={name} />
        <RestaurantsDetail {...this.props} />
        <RestaurantsTile images={gridImages} />
      </div>
    );
  }
}

// ********************************* Render page *********************************
const RestaurantPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <RestaurantPageTemplate {...frontmatter} />;
};

export default RestaurantPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query RestaurantIndividualPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        name
        desc
        address
        tel
        openingHours {
          time
        }
        menuLink {
          publicURL
        }
        coverImages {
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          mobileImg {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        gridImages {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        coverIcon {
          alt
          image {
            childImageSharp {
              fixed(width: 1500, quality: 90) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
`;
