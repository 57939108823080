import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';

import { justifyContent } from 'styled-system';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import FlexBox from '../Atoms/FlexBox';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';
import RevealParagraph from '../Atoms/RevealParagraph';
import Button from '../Atoms/Button';
import Paragraph from '../Atoms/Paragraph';
import { descDivider } from '../../utilis/helpers';
import CustomLink from '../Atoms/CustomLink';

class RestaurantsDetail extends Component {
  render() {
    const {
      name, desc, address, tel, menuLink, openingHours,
    } = this.props;

    return (
      <Section className="fp-auto-height" py={100} textAlign="center">
        <Container>
          <Reveal duration={1000} effect="section-content-ani">
            <Row>
              <Column col="is-10 is-offset-1" pb={6}>
                <Reveal duration={1000} effect="section-title-ani">
                  <Heading>{name}</Heading>
                </Reveal>
                <RevealParagraph noEffect desc={desc} />
              </Column>
            </Row>
            <Row mobile multi justifyContent="center">
              {openingHours
                && openingHours.map((open, i) => (
                  <Column col="is-6-mobile is-3-desktop" key={i} pb={5}>
                    {descDivider(open.time).map(val => (
                      <Paragraph key={val} pb={1} fontWeight={700}>
                        {val}
                      </Paragraph>
                    ))}
                  </Column>
                ))}
            </Row>
            <Row>
              <Column>
                <FlexBox width="100%">
                  <Paragraph width={['100%', '100%', '100%', 'auto']} mr={[0, 0, 0, 5]}>{address}</Paragraph>
                  <Paragraph>{tel}</Paragraph>
                </FlexBox>
              </Column>
            </Row>
            <Row>
              <Column col="is-12">
                <CustomLink to={menuLink.publicURL} openNewTab>
                  <FlexBox>
                    <Button width={160} text="seeMenu" />
                  </FlexBox>
                </CustomLink>
              </Column>
            </Row>
          </Reveal>
        </Container>
      </Section>
    );
  }
}

export default RestaurantsDetail;
