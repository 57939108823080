import React, { Component } from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Box from '../Atoms/Box';
import ThreeTileLargeLeft from './ThreeTileLargeLeft';
import ThreeTileLargeRight from './ThreeTileLargeRight';
import TwoTile from './TwoTile';

const FullScreenContainer = styled(Container)`
  margin-left: 0 !important;
  margin-right: 0 !important;

  .tile {
    overflow: hidden;
  }

  .tile .gatsby-image-wrapper {
    transition: transform 400ms cubic-bezier(0.15, 0.69, 0.32, 1);
    &:hover {
      transform: scale(1.1);
    }
  }
`;

class RestaurantsTile extends Component {
  render() {
    const { images } = this.props;
    const cArr = chunk(images, 3);
    const last = cArr[cArr.length - 1];
    if (last.length === 1) {
      const f = cArr[cArr.length - 2].pop();
      cArr[cArr.length - 1].push(f);
    }
    return (
      <Section className="restaurants-title" p={0} onScroll={this.handleScroll}>
        <Box width="100%" height="100%">
          <FullScreenContainer fluid>
            {cArr.map((c, i) => {
              if (c.length === 3 && i % 2 === 0) {
                return <ThreeTileLargeLeft key={i} images={c} />;
              }
              if (c.length === 3 && i % 2 !== 0) {
                return <ThreeTileLargeRight key={i} images={cArr[1]} />;
              }
              if (c.length === 2) {
                return <TwoTile key={i} images={c} />;
              }
              return null;
            })}
          </FullScreenContainer>
        </Box>
      </Section>
    );
  }
}

export default RestaurantsTile;
