import React from 'react';
import PreviewCompatibleImage from '../Atoms/PreviewCompatibleImage';

function TwoTile(props) {
  const { images } = props;

  return (
    <div className="tile is-ancestor">
      <div className="tile is-parent">
        <div className="tile is-child box">
          <PreviewCompatibleImage imageInfo={images[0]} imageStyle={{ maxHeight: '550px' }} />
        </div>
      </div>
      <div className="tile is-6 is-vertical is-parent">
        <div className="tile is-child box">
          <PreviewCompatibleImage imageInfo={images[1]} imageStyle={{ maxHeight: '550px' }} />
        </div>
      </div>
    </div>
  );
}

export default TwoTile;
