import React, { Component } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { UAParser } from 'ua-parser-js';
import smoothscroll from 'smoothscroll-polyfill';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import ImageSwiper from '../ImageSwiper';
import Box from '../Atoms/Box';
import PreviewCompatibleImage from '../Atoms/PreviewCompatibleImage';
import FlexBox from '../Atoms/FlexBox';

const FullScreenContainer = styled(Container)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

class RestaurantsHeader extends Component {
  lastLocation = 0;

  scrolling = false;

  handleScroll = (e) => {
    const currTop = typeof window !== 'undefined' ? window.pageYOffset : false;
    const headerTop = document && document.getElementById('restaurant-header')
      ? document.getElementById('restaurant-header').offsetHeight
      : false;

    // determent if user is going up or down
    const goUp = currTop <= this.lastLocation;
    this.lastLocation = currTop;

    if (this.scrolling && (currTop === headerTop || currTop === 0 || currTop > headerTop)) {
      this.scrolling = false;
      return;
    }

    if (typeof window === 'undefined' || !currTop || !headerTop) {
      return;
    }

    if (!this.scrolling && currTop < headerTop) {
      this.scrolling = true;
      window.scroll({ top: goUp ? 0 : headerTop, left: 0, behavior: 'smooth' });
      // window.scrollTo(0, goUp ? 0 : headerTop);
    }
  };

  componentDidMount() {
    smoothscroll.polyfill();

    const parser = new UAParser();
    const p = parser.getDevice();

    if (typeof window !== 'undefined' && !p.type) {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  }

  render() {
    const { images, coverIcon } = this.props;

    return (
      <Section p={0} id="restaurant-header">
        <FullScreenContainer fluid>
          <ImageSwiper list={images} />
          <FlexBox posAb top={0} left={0} width="100vw" height="100vh" zIndex={10}>
            <Box lineHeight={0} width={['50%', '40%', '70%', '50%']} m="auto">
              <Fade delay={500}>
                <Box m="auto" width="100%" maxWidth={300}>
                  <PreviewCompatibleImage imageInfo={coverIcon} imageStyle={{ width: '100%' }} />
                </Box>
              </Fade>
            </Box>
          </FlexBox>
        </FullScreenContainer>
      </Section>
    );
  }
}

export default RestaurantsHeader;
